<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      aa: 111,
    };
  },
  mounted() {
    const _this = this;
    this.aa = this.getC();
    //console.log(encodeURI('https://video.tanzhiw.com/#/Template9-1/?id=111&ip=192.168.10.203&port=8778&source=local&model_id=9&model_name=行情回顾&model_category=1&url_extra_params={"dt_stock_code": "0001000783", "stock_code": "000783", "stock_name": "长江证券", "industry_code": "2020880049", "industry_name": "证券"}&offset_x=0&offset_y=0&width=568&height=1024'))
    console.log(
      decodeURIComponent(
        "https://video.tanzhiw.com/#/Template9-1/?id=111&ip=192.168.10.203&port=8778&source=local&model_id=9&model_name=%E8%A1%8C%E6%83%85%E5%9B%9E%E9%A1%BE&model_category=1&url_extra_params=%7B%22dt_stock_code%22%3A+%220001000783%22%2C+%22stock_code%22%3A+%22000783%22%2C+%22stock_name%22%3A+%22%E9%95%BF%E6%B1%9F%E8%AF%81%E5%88%B8%22%2C+%22industry_code%22%3A+%222020880049%22%2C+%22industry_name%22%3A+%22%E8%AF%81%E5%88%B8%22%7D&offset_x=0&offset_y=0&width=568&height=1024"
      )
    );
  },
  methods: {
    getC(aa = 1) {
      aa++;
      if (aa < 5) {
        return this.getC(aa);
      }
      return aa;
    },
  },
};
</script>
